<template>
  <QRView />
</template>

<script>
import QRView from "./view/QRView.vue";
export default {
  name: "App",
  components: {
    QRView,
  },
};
</script>

<style lang="scss" src="./styles/globals.scss"></style>
